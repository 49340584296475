import React, { useMemo, useRef } from 'react'
import type { LocationState, NavigateMethod, SetSearchParamsFn, SearchParamsContextValue } from './types'
import { LocationStateContext, NavigateContext, PathnameContext, SearchContext, SearchParamsContext } from './contexts'


type RouterProps = {
  children: React.ReactNode
  location: LocationState
  navigate: NavigateMethod
}

export const BaseRouter: React.FC<RouterProps> = (props) => {
  const { children, location, navigate } = props

  // to keep fresh version of location
  const locationRef = useRef(location)
  locationRef.current = location

  const setSearchParamsRef = useRef<SetSearchParamsFn>(null)

  if (!setSearchParamsRef.current) {
    setSearchParamsRef.current = (input, options = {}) => {
      const { replace = false, scroll = true } = options

      const newSearchParams = typeof input === 'function' ? input(locationRef.current.searchParams) : input
      navigate(locationRef.current.pathname, {
        searchParams: newSearchParams,
        replace,
        scroll,
      })
    }
  }

  // to fix useSearchParams rerender on pathname change
  const searchParamsContext = useMemo<SearchParamsContextValue>(() => [ location.searchParams, setSearchParamsRef.current ], [ location.searchParams ])

  return (
    <NavigateContext.Provider value={navigate}>
      <LocationStateContext.Provider value={location}>
        <PathnameContext.Provider value={location.pathname}>
          <SearchContext.Provider value={location.search}>
            <SearchParamsContext.Provider value={searchParamsContext}>
              {children}
            </SearchParamsContext.Provider>
          </SearchContext.Provider>
        </PathnameContext.Provider>
      </LocationStateContext.Provider>
    </NavigateContext.Provider>
  )
}
