class Manager implements ReactDnD.Manager {

  nodes: ReactDnD.ManagerNode[] = []

  add(node: ReactDnD.ManagerNode) {
    const index = this.nodes.indexOf(node)

    if (index === -1) {
      this.nodes.push(node)
    }
    else {
      this.nodes[index] = node
    }

    this._sortNodes()
  }

  remove(node: ReactDnD.ManagerNode) {
    const index = this.nodes.indexOf(node)

    if (index > -1) {
      this.nodes.splice(index, 1)
      this._sortNodes()
    }
  }

  getNodes(): ReactDnD.ManagerNode[] {
    return this.nodes
  }

  getActiveNodes(): ReactDnD.ManagerNode[] {
    return this.nodes.filter((node) => !node.dndData.disabled)
  }

  getNodeIndex(node: ReactDnD.ManagerNode) {
    return this.nodes.indexOf(node)
  }

  flush() {
    this.nodes = []
  }

  _sortNodes() {
    this.nodes.sort((a, b) => {
      const { dndData: { index: aIndex } } = a
      const { dndData: { index: bIndex } } = b

      return aIndex === bIndex ? 0 : (
        aIndex > bIndex ? 1 : -1
      )
    })
  }
}


export default Manager
