import React, { useEffect, useRef } from 'react'

import { useManagerContext } from './ManagerContext'


type DragElementProps = {
  children: React.ReactElement
  index: number
  disabled?: boolean
  handle?: React.RefObject<HTMLElement>
}

const DragElement: React.FunctionComponent<DragElementProps> = ({ children, index, disabled, handle }) => {
  const elementRef = useRef<ReactDnD.ManagerNode>(null)

  const manager = useManagerContext()

  useEffect(() => {
    if (!elementRef.current) {
      return null
    }

    const node = elementRef.current

    node.dndData = {
      index,
      disabled,
      handle: handle ? handle.current : null,
    }

    manager.add(node)

    return () => {
      manager.remove(node)
      delete node.dndData
    }
  }, [ index, disabled, handle, manager ])

  return React.cloneElement(React.Children.only(children), {
    ref: elementRef,
  })
}


export default React.memo(DragElement)
