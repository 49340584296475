type AutoScrollCallback = (offset: { left: number, top: number }) => void


class AutoScroller {
  container: Element
  onAutoscroll: AutoScrollCallback

  interval
  isAutoScrolling: boolean = false

  constructor(container: Element, onAutoscroll: AutoScrollCallback) {
    this.container = container
    this.onAutoscroll = onAutoscroll
  }

  clear() {
    cancelAnimationFrame(this.interval)
    this.interval = null
  }

  // helps to scroll to have rect in view
  update(rect: DOMRect) {
    const direction = {
      x: 0,
      y: 0,
    }
    const speed = {
      x: 1,
      y: 1,
    }
    const acceleration = {
      x: 30,
      y: 30,
    }

    const {
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      clientHeight,
      clientWidth,
    } = this.container

    const isTop = scrollTop === 0
    const isBottom = scrollHeight - scrollTop - clientHeight === 0
    const isLeft = scrollLeft === 0
    const isRight = scrollWidth - scrollLeft - clientWidth === 0

    const {
      left: x,
      top: y,
      width,
      height,
    } = rect

    const minX = 0
    const minY = 0
    const maxX = clientWidth - width
    const maxY = clientHeight - height

    if (y >= maxY && !isBottom) {
      // Scroll Down
      direction.y = 1
      speed.y = acceleration.y * Math.abs((maxY - y) / height)
    }
    else if (x >= maxX && !isRight) {
      // Scroll Right
      direction.x = 1
      speed.x = acceleration.x * Math.abs((maxX - x) / width)
    }
    else if (y <= minY && !isTop) {
      // Scroll Up
      direction.y = -1
      speed.y = acceleration.y * Math.abs((y - minY) / height)
    }
    else if (x <= minX && !isLeft) {
      // Scroll Left
      direction.x = -1
      speed.x = acceleration.x * Math.abs((x - minX) / width)
    }

    if (this.interval) {
      this.clear()
      this.isAutoScrolling = false
    }

    if (direction.x !== 0 || direction.y !== 0) {
      const frame = () => {
        this.isAutoScrolling = true
        const offset = {
          left: speed.x * direction.x,
          top: speed.y * direction.y,
        }
        this.container.scrollTop += offset.top
        this.container.scrollLeft += offset.left

        this.onAutoscroll(offset)

        this.interval = requestAnimationFrame(frame)
      }

      this.interval = requestAnimationFrame(frame)
    }
  }
}


export default AutoScroller
