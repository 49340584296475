import { getFocusableChildren } from 'helpers/getters'


class TrapFocus {

  element: HTMLElement
  options = {
    withInitialFocus: true,
  }

  constructor(element: HTMLElement, options = {}) {
    this.element = element
    this.options = { ...this.options, ...options }
  }

  mount = () => {
    if (!this.element) {
      console.error('element was not provided in constructor')
      return
    }

    const { options: { withInitialFocus }, element } = this

    const focusableElements = getFocusableChildren(element)
    const firstFocusableEl = focusableElements[0]

    if (withInitialFocus && firstFocusableEl) {
      firstFocusableEl.focus()
    }

    element.addEventListener('keydown', this.listener)
  }

  unmount = () => {
    if (!this.element) {
      console.error('element was not provided in constructor')
      return
    }

    this.element.removeEventListener('keydown', this.listener)
  }

  listener = (event: KeyboardEvent) => {
    const isTabPressed = event.key === 'Tab' || event.keyCode === 9

    if (!isTabPressed) {
      return
    }

    const focusableElements = getFocusableChildren(this.element)
    const firstFocusableEl = focusableElements[0]
    const lastFocusableEl = focusableElements[focusableElements.length - 1]

    if (event.shiftKey) {
      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus()
        event.preventDefault()
      }
    }
    else {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus()
        event.preventDefault()
      }
    }
  }
}


export default TrapFocus
