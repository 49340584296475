import React from 'react'
import Context from './Context'


const Provider: DeviceProvider = ({ children, value }) => (
  <Context.Provider value={value}>
    {children}
  </Context.Provider>
)


export default Provider
