import EventEmitter from 'event-emitter'
import logger from 'logger'


type NotificationProps = { [key: string]: any }
type OpenedNotification = { id: number, name: string, props: NotificationProps }
type OpenedNotifications = OpenedNotification[]

type EventMap = {
  change: (notifications: OpenedNotifications) => void
}

const events = new EventEmitter<EventMap>()

let notifications: OpenedNotifications = []
let notificationId = 1

const subscribe = (handler: EventMap['change']) => {
  events.addListener('change', handler)
}

const unsubscribe = (handler: EventMap['change']) => {
  events.removeListener('change', handler)
}

const openNotification = <K extends keyof Notifications>(name: K, props?: Notifications[K]): number => {
  const currentNotificationId = ++notificationId

  const notification = { id: currentNotificationId, name, props }

  notifications = [ ...notifications, notification ]

  logger.debug({ props }, `Open "${name}" notification`)

  events.emit('change', notifications)

  return currentNotificationId
}

const closeNotification = (id: number): void => {
  const notification = notifications.find((notification) => notification.id === id)

  if (notification) {
    notifications = notifications.filter((notification) => notification.id !== id)

    logger.debug(`Close "${notification.name}" notification`)
    events.emit('change', notifications)
  }
}

const closeAllNotifications = (): void => {
  notifications.forEach((notification) => {
    closeNotification(notification.id)
  })
}


export {
  subscribe,
  unsubscribe,
  openNotification,
  closeNotification,
  closeAllNotifications,
}
