import React, { useEffect, useRef } from 'react'
import { getLinkWithParams } from './utils'
import { useNavigate, useSearchParams } from './hooks'
import type { Match, RedirectCode, SearchParams, NavigateOptions } from './types'


export type RedirectProps = {
  path?: string
  to: string
  searchParams?: SearchParams
  withSearch?: boolean
  match?: Match // controlled by Switch
  code?: RedirectCode
}

export const Redirect: React.FunctionComponent<RedirectProps> = ({ to, searchParams, withSearch: customWithSearch, match, code = 302 }) => {
  const navigate = useNavigate()
  const [ existingSearchParams ] = useSearchParams()
  const isRedirected = useRef(false)
  const redirectRef = useRef(null)

  const params = match?.params || {}

  // ATTN we must save utm params on server side, because we register them on the client-side
  const withSearch = __SERVER__ || customWithSearch

  redirectRef.current = () => {
    if (isRedirected.current) {
      return null
    }

    let link = to

    // if Redirect rendered by Switch, there will be always "params" so we should check keys
    if (Object.keys(params).length) {
      link = getLinkWithParams(to, params)
    }

    const options: NavigateOptions = {
      code,
      replace: true,
    }

    if (withSearch) {
      options.searchParams = {
        ...existingSearchParams,
        ...searchParams,
      }
    }

    navigate(
      link,
      options
    )

    isRedirected.current = true
  }

  if (__CLIENT__) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      redirectRef.current()
    }, [])
  }
  else {
    redirectRef.current()
  }

  return null
}
