import React, { useRef, useCallback } from 'react'


type Opts = {
  stickyClass: string | string[]
}

type Result = [
  React.RefObject<any>,
  React.RefObject<any>,
  (isSticky: boolean) => void
]

const useSticky = (opts: Opts): Result => {
  const containerRef = useRef<any>()
  const elementRef = useRef<any>()
  const stickyRef = useRef<boolean>()

  const setSticky = useCallback((isSticky) => {
    if (!containerRef.current || !elementRef.current) {
      return
    }

    stickyRef.current = isSticky

    const classNames = [].concat(opts.stickyClass)

    if (isSticky) {
      containerRef.current.style = `width:${elementRef.current.clientWidth}px;height:${elementRef.current.clientHeight}px`
      elementRef.current.classList.add(...classNames)
    }
    else {
      containerRef.current.style = null
      elementRef.current.classList.remove(...classNames)
    }
  }, [ opts.stickyClass ])

  return [ containerRef, elementRef, setSticky ]
}


export default useSticky
