import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { type ModalComponent, type ModalName, manager } from './manager'


const useIsomorphicLayoutEffect = __CLIENT__ ? useLayoutEffect : useEffect

// to render a modal in-place without registration required
const standaloneModal = (modalName: ModalName, Component: ModalComponent) => {
  // ATTN because we can render several instances of standaloneModal,
  //  we should track them and render only in the active one
  let lastId = 1
  let activeIds = []

  const Wrapper: React.FC = () => {
    const [ , forceUpdate ] = useState(0)
    const instanceIdRef = useRef(null)

    if (!instanceIdRef.current) {
      instanceIdRef.current = lastId++
    }

    useIsomorphicLayoutEffect(() => {
      const listener = () => {
        forceUpdate((value) => (value + 1))
      }

      activeIds.push(instanceIdRef.current)
      manager.subscribe(listener)
      manager.markAsStandalone(modalName)
      // Force update if modal was open before
      listener()

      return () => {
        activeIds = activeIds.filter((value) => value !== instanceIdRef.current)
        manager.unsubscribe(listener)
        manager.unmarkAsStandalone(modalName)
      }
    }, [])

    // ATTN show only if this instance is the first instance of this modal
    if (activeIds[0] !== instanceIdRef.current) {
      return null
    }

    const nodes = []
    let index = -1

    for (const [ id, { name, props, closeModal } ] of manager.getState().entries()) {
      index++

      if (modalName === name) {
        nodes.push(
          <Component
            key={id}
            name={name}
            closeModal={closeModal}
            index={index}
            {...props}
          />
        )
      }
    }

    return (
      <>
        {nodes}
      </>
    )
  }

  Wrapper.displayName = `modal(${Component.displayName || Component.name || modalName})`

  return Wrapper
}


export default standaloneModal
