import React, { useMemo } from 'react'

import { IntlContext } from './IntlContext'
import IntlManager from './IntlManager'


type IntlProviderProps = {
  children: React.ReactNode
  locale: Intl.LocaleCode
  currency: Intl.CurrencyCode
}

const IntlProvider: React.FunctionComponent<IntlProviderProps> = (props) => {
  const { children, locale, currency } = props

  const intl = useMemo(() => {
    return new IntlManager({ locale, currency })
  }, [ locale, currency ])

  return (
    <IntlContext.Provider value={intl}>
      {children}
    </IntlContext.Provider>
  )
}


export default IntlProvider
