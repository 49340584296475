import React, { useState } from 'react'

import useEntryListener from './useEntryListener'


type Opts = {
  observerProps?: IntersectionObserverInit
  once?: boolean
}

type Result<Opts> = [
  React.RefObject<any>,
  IntersectionObserverEntry,
  () => void,
  () => void
]

const useEntry = (opts: Opts = {}): Result<Opts> => {
  const [ entry, setEntry ] = useState<IntersectionObserverEntry>(null)
  const [ ref, unobserve, refresh ] = useEntryListener(setEntry, opts)

  return [ ref, entry, unobserve, refresh ]
}


export default useEntry
