import type React from 'react'
import EventEmitter from 'event-emitter'
import logger from 'logger'


declare global {

  // ATTN extend this type to provide correct modal name and props mapping
  // Record type is required for custom modals without registration
  interface ModalsRegistry {
    _: any
  }

  type ExtendModalsRegistry<T> = {
    [K in keyof T]: ExtractModalProps<T[K]>
  }

  /**
   * To extend registry:
   * declare global { interface ModalsRegistry extends ExtendModalsRegistry<typeof newRegistry> {} }
   */
}

type ExtractModalProps<T> = Omit<T extends React.JSXElementConstructor<infer P> ? P : object, 'fallback' | 'closeModal' | 'name'>

type RequiredLiteralKeys<T> = keyof { [K in keyof T as string extends K ? never : number extends K ? never :
  object extends Pick<T, K> ? never : K]: 0 }

// if modal props has any required property, it makes props argument required, otherwise it's optional
type OpenModalArgs<P> = RequiredLiteralKeys<P> extends never ? [] | [ P ] : [ P ]
type OpenModal = <K extends ModalName = ModalName>(name: K, ...args: OpenModalArgs<ModalsRegistry[K]>) => () => void

export type ModalComponentProps<P = object> = P & { name: string, closeModal: (withOnClose?: boolean) => void, onClose?: () => void }

export type ModalComponent<P = object> = React.ComponentType<ModalComponentProps<P>> & { load?: () => Promise<any> }

type Listener = () => void

export type ModalName = keyof ModalsRegistry

type OpenModalsState<K extends ModalName = ModalName> = Map<number, { name: K, props?: ModalsRegistry[K], closeModal: (withOnClose?: boolean) => void, isStandalone: boolean }>


class ModalManager {
  private events = new EventEmitter()
  private state: OpenModalsState = new Map()
  public registry: Map<string, ModalComponent> = new Map()
  private standaloneModals: Record<string, number> = {}
  private lastId = 0

  private generateId() {
    return this.lastId++
  }

  public subscribe(listener: Listener) {
    this.events.addListener('change', listener)
  }

  public unsubscribe(listener: Listener) {
    this.events.removeListener('change', listener)
  }

  public emit() {
    this.events.emit('change')
  }

  public openModal<K extends ModalName = ModalName>(name: K, props?: ModalsRegistry[K]) {
    const id = this.generateId()
    const isStandalone = this.standaloneModals[name] > 0

    const closeModal = (withOnClose?: boolean) => {
      this.state.delete(id)
      this.emit()
      logger.debug(`Close "${name}" modal`)

      if (withOnClose === true && typeof props?.onClose === 'function') {
        props?.onClose()
      }
    }

    this.state.set(id, { name, props, closeModal, isStandalone })
    this.emit()

    logger.debug({ props }, `Open "${name}" modal`)

    return closeModal
  }

  // closes every modal with requested name
  public closeModal(name: ModalName) {
    this.state.forEach((value, key) => {
      if (value.name === name) {
        this.state.delete(key)
        logger.debug(`Close "${name}" modal`)
      }
    })
    this.emit()
  }

  public getState() {
    return this.state
  }

  public markAsStandalone(name: string) {
    this.standaloneModals[name] = (this.standaloneModals[name] || 0) + 1
  }

  public unmarkAsStandalone(name: string) {
    this.standaloneModals[name] = Math.max((this.standaloneModals[name] || 1) - 1, 0)
  }

  public isModalOpen(name: string): boolean {
    for (const item of this.state.values()) {
      if (item.name === name) {
        return true
      }
    }
    return false
  }

  public isAnyModalOpen(): boolean {
    return this.state.size > 0
  }
}

export const manager = new ModalManager()

export const openModal: OpenModal = (name, props) => manager.openModal(name, props)
export const closeModal = (name: ModalName) => manager.closeModal(name)

export const registerModals = (registry: Record<string, ModalComponent>) => {
  Object.keys(registry).forEach((key) => {
    manager.registry.set(key, registry[key])
  })

  // unregister function
  return () => {
    Object.keys(registry).forEach((key) => {
      manager.registry.delete(key)
    })
  }
}

export const preloadModal = (...names: ModalName[]): Promise<void> => (
  Promise.all(names.map((name) => {
    if (!manager.registry.has(name)) {
      throw new Error(`Can't preload unregistered modal "${name}"`)
    }

    const modal = manager.registry.get(name)

    return typeof modal.load === 'function' ? modal.load() : Promise.resolve()
  })).then(() => {})
)

export const isModalOpen = (name: string) => manager.isModalOpen(name)

export const isAnyModalOpen = () => manager.isAnyModalOpen()
