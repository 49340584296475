import * as Sentry from '@sentry/browser'
import { buildTransport, composeError, type Level } from '@scentbird/logger'


const sentryLevelMap: Record<Level, Sentry.SeverityLevel> = {
  fatal: 'fatal',
  error: 'error',
  warn: 'warning',
  info: 'info',
  debug: 'debug',
  trace: 'log',
}

type SentryTransportOptions = {
  // levels to log
  levels: Sentry.SeverityLevel[]
}

export const sentryTransport = (options: SentryTransportOptions) => {
  const { levels } = options

  return buildTransport((source) => {
    const { message, error, level, sentryIgnore, ...rest } = source
    const severity = sentryLevelMap[level] || 'log'

    if (sentryIgnore) {
      return
    }

    if (!levels.includes(severity)) {
      Sentry.addBreadcrumb({
        category: 'log',
        level: severity,
        message: String(message),
        data: rest,
      })
      return
    }

    Sentry.withScope((scope) => {
      scope.setLevel(severity)
      scope.setContext('log-record', source)

      if (error) {
        const composedError = composeError(error)
        Sentry.captureException(composedError)
      }
      else {
        Sentry.captureMessage(message)
      }
    })
  }, { parse: true })
}
