const findClosest = (element: Node, condition: (Node) => boolean): Node => {
  let el = element
  while (el) {
    if (condition(el)) {
      return el
    }
    el = el.parentNode
  }
}

const isTouchEvent = (event: MouseEvent | TouchEvent): event is TouchEvent => ('touches' in event)
const isMouseEvent = (event: MouseEvent | TouchEvent): event is MouseEvent => !('touches' in event)

const getCursorPosition = (event: MouseEvent | TouchEvent) => ({
  x: 'touches' in event ? event.touches[0].clientX : event.clientX,
  y: 'touches' in event ? event.touches[0].clientY : event.clientY,
})


const vendorPrefix: string = ((() => {
  if (typeof window === 'undefined' || typeof document === 'undefined') return '' // server environment
  // fix for:
  //    https://bugzilla.mozilla.org/show_bug.cgi?id=548397
  //    window.getComputedStyle() returns null inside an iframe with display: none
  // in this case return an array with a fake mozilla style in it.
  const styles = window.getComputedStyle(document.documentElement, '') || [ '-moz-hidden-iframe' ]
  // @ts-expect-error oLink
  const pre = (Array.prototype.slice.call(styles).join('').match(/-(moz|webkit|ms)-/) || (styles.OLink === '' && [ '', 'o' ]))[1]

  if (pre === 'ms') {
    return pre
  }

  return pre?.length ? pre[0].toUpperCase() + pre.substr(1) : ''
})())


export {
  findClosest,
  getCursorPosition,
  isTouchEvent,
  isMouseEvent,
  vendorPrefix,
}
