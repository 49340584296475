import React, { useState, useCallback, useEffect } from 'react'

import { subscribe, unsubscribe, closeNotification } from './manager'


type NotificationProps = {
  id: number
  component: React.ComponentType<any>
  componentProps: { [key: string]: any }
}

const Notification: React.FunctionComponent<NotificationProps> = ({ id, component, componentProps }) => {
  const handleClose = useCallback(() => {
    closeNotification(id)
  }, [ id ])

  return React.createElement(component, {
    ...componentProps,
    closeNotification: handleClose,
  })
}


type NotificationsProps = {
  className?: string
  templates?: {
    [key: string]: React.ComponentType<any>
  }
  style?: any // only for storybook
}

const Notifications: React.FunctionComponent<NotificationsProps> = (props) => {
  const { className, templates, style } = props

  const [ notifications, setNotifications ] = useState([])

  useEffect(() => {
    subscribe(setNotifications)

    return () => {
      unsubscribe(setNotifications)
    }
  }, [])

  return (
    <div className={className} style={style}>
      {
        notifications.map(({ id, name, props }) => {
          const component = templates[name]
          return (
            <Notification
              key={id}
              id={id}
              component={component}
              componentProps={props}
            />
          )
        })
      }
    </div>
  )
}

export default React.memo(Notifications)
