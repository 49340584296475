import logger from 'logger'


type Event = {
  methodName: string
  data: any[]
}

type Entity = {
  events: Event[]
  watching: boolean
  callback: (event: Event) => void
}

const eventsSaver = (entityKey: string) => {
  const events: Array<Event> = []

  const entity: Entity = {
    events,
    callback: null,
    watching: false,
  }

  if (window.eventsSaver) {
    window.eventsSaver[entityKey] = entity
  }
  else {
    window.eventsSaver = {
      [entityKey]: entity,
    }
  }

  return (event: Event) => {
    try {
      const { watching, callback } = window.eventsSaver[entityKey] || {}

      if (watching) {
        events.push(event)
      }

      if (typeof callback === 'function') {
        callback(event)
      }
    }
    catch (error) {
      logger.error(error)
    }
  }
}


export default eventsSaver
