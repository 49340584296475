import logger from 'logger'
import replaceSimpleVariables from './util/replaceSimpleVariables'
import replacePluralVariables from './util/replacePluralVariables'
import replaceSelectVariables from './util/replaceSelectVariables'
import stripSpaces from './util/stripSpaces'


const formatMessage = (locale: Intl.Locale, message: Intl.Message | string, values?: Intl.MessageValues) => {
  if (typeof message === 'string') {
    return message
  }

  // TODO add error handler - added on 10/9/20 by pavelivanov
  if (!message) {
    return '{{ missed_translation }}'
  }

  // fallback to locale language or en
  let fLocale = 'en'
  if (message[locale.baseName]) {
    fLocale = locale.baseName
  }
  else if (message[locale.language]) {
    fLocale = locale.language
  }

  let fMessage = message[fLocale]

  if (!fMessage) {
    return '{{ missed_translation }}'
  }

  try {
    fMessage = stripSpaces(fMessage)
    const fValues = values || message?.values

    fMessage = replaceSelectVariables(fMessage, fValues)
    fMessage = replacePluralVariables(fMessage, fValues, fLocale)
    fMessage = replaceSimpleVariables(fMessage, fValues)
  }
  catch (error) {
    logger.error({ error, props: { message, values, locale } }, 'formatMessage error')
    return '{{ error }}'
  }

  return fMessage || '{{ missed_translation }}'
}


export default formatMessage
