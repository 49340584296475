import { createContext } from 'react'
import type { LocationState, NavigateMethod, Params, SearchParamsContextValue } from './types'


export const LocationStateContext = createContext<LocationState>(null)
export const NavigateContext = createContext<NavigateMethod>(null)
export const PathnameContext = createContext<string>(null)
export const SearchContext = createContext<string>(null)
export const SearchParamsContext = createContext<SearchParamsContextValue>(null)
export const RouteParamsContext = createContext<Params>(null)
