// Global context for analytics events
type Params = {
  page?: string
  pageType?: string
  [k: string]: string | number | boolean
}

let globalContext: Params = {}

export const setAnalyticsContext = (context: Params) => {
  globalContext = context
}

export const updateAnalyticsContext = (context: Params) => {
  globalContext = {
    ...globalContext,
    ...context,
  }
}

export const getAnalyticsContext = () => globalContext
