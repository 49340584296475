import logger from 'logger'
import analyticsWrapper from '../wrapper'


type DataLayerObject = {
  [key: string]: any
}

type Events = Analytics.DataLayerEvents

type Methods = {
  track: Analytics.TrackFunction<Events>
  push: (params: Partial<DataLayerObject>) => void
}

const dataLayer = analyticsWrapper<Methods>({
  entityKey: 'dataLayer',
  resolveMethods: {
    track: (event, params) => {
      logger.info({ params }, '[DataLayer] track %s', event)

      try {
        window.dataLayer.push({ event, ...params })
      }
      catch (error) {
        logger.warn(error)
      }
    },
    push: (params) => {
      window.dataLayer.push(params)
    },
  },
})


export default dataLayer
