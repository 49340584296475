import { Logger } from '@scentbird/logger'
import { consoleTransport } from './consoleTransport'
import { sentryTransport } from './sentryTransport'


const logger = new Logger({
  transports: [
    consoleTransport,
    sentryTransport({ levels: [ 'error', 'fatal' ] }),
  ],
})

export default logger

