import React, { createContext, useContext } from 'react'


const SvgProviderContext = createContext<SvgProvider.Runtime>(null)

type SvgProviderProps = {
  children?: React.ReactNode
  value: SvgProvider.Runtime
}

const SvgProvider: React.FunctionComponent<SvgProviderProps> = ({ children, value }) => {
  return (
    <SvgProviderContext.Provider value={value}>
      {children}
    </SvgProviderContext.Provider>
  )
}


export const useSvgProvider = () => useContext(SvgProviderContext)

export default SvgProvider
