import React, { PureComponent, createRef } from 'react'

import AutoScroller from './AutoScroller'
import Manager from './Manager'
import { ManagerProvider } from './ManagerContext'

import { findClosest, getCursorPosition, isTouchEvent, vendorPrefix } from './helpers'


type Mode = 'idle' | 'touch' | 'mouse' | 'keyboard'

type DragLayerProps = {
  children: React.ReactElement
  scrollingContainerSelector?: string
  axis?: 'x' | 'y' | 'xy'
  touchDelay?: number
  mouseDelay?: number
  onDragEnd?: (oldIndex: number, newIndex: number) => void
}


class DragLayer extends PureComponent<DragLayerProps> {

  manager: Manager = new Manager()
  autoScroller: AutoScroller

  containerRef = createRef<HTMLElement>()

  // helper
  helper: HTMLElement
  helperRect: DOMRect

  // initial state
  mode: Mode
  isDelayed: boolean
  delayTimeout: any
  activeNode: ReactDnD.ManagerNode
  initialEvent: MouseEvent | TouchEvent
  initialPosition: { x: number, y: number }
  initialScroll: { left: number, top: number }
  scrollingContainer: Element

  lastPosition: { x: number, y: number}
  lastHoveredNode: ReactDnD.ManagerNode

  static defaultProps = {
    axis: 'xy',
  }

  componentDidMount() {
    const { scrollingContainerSelector } = this.props

    const container = this.containerRef.current

    container.addEventListener('mousedown', this.handleStart, { passive: false })
    container.addEventListener('touchstart', this.handleStart, { passive: false })
    // we should apply touchmove at mount, because it should prevent browser from scrolling
    // and should be added before actual events
    container.addEventListener('touchmove', this.handleContainerMove, { passive: false })
    container.addEventListener('keydown', this.handleKeyStart, { passive: false })

    this.scrollingContainer = scrollingContainerSelector ? (
      document.querySelector(scrollingContainerSelector)
    ) : (
      document.scrollingElement || document.documentElement
    )

    if (this.scrollingContainer) {
      this.autoScroller = new AutoScroller(this.scrollingContainer, this.handleScroll)
    }
    else {
      console.error('Scrolling container not found')
    }

    this.mode = 'idle'
  }

  componentWillUnmount() {
    const container = this.containerRef.current

    container.removeEventListener('mousedown', this.handleStart)
    container.removeEventListener('touchstart', this.handleStart)
    container.removeEventListener('touchmove', this.handleContainerMove)
    container.removeEventListener('keydown', this.handleKeyStart)

    this.cleanup()
  }

  createHelper() {
    if (this.helper || !this.activeNode) {
      return
    }

    const bounds = this.activeNode.getBoundingClientRect()
    this.helper = this.activeNode.cloneNode(true) as HTMLElement

    this.helper.style.position = 'fixed'
    this.helper.style.top = `${bounds.top}px`
    this.helper.style.left = `${bounds.left}px`
    this.helper.style.width = `${bounds.width}px`
    this.helper.style.height = `${bounds.height}px`
    this.helper.style.boxSizing = 'border-box'
    this.helper.style.pointerEvents = 'none'
    this.helper.style.userSelect = 'none'
    this.helper.style.willChange = `transform, ${vendorPrefix}Transform`
    this.helper.style.zIndex = '600'
    this.activeNode.style.visibility = 'hidden'

    this.helper.setAttribute('aria-label', 'Press Up or Down to move the item, press Space to apply, press Esc to cancel')

    document.body.appendChild(this.helper)
  }

  updateHelper() {
    const { axis } = this.props

    // fix helper position
    const translate = {
      // fixing one axis
      x: axis === 'y' ? 0 : this.lastPosition.x - this.initialPosition.x,
      y: axis === 'x' ? 0 : this.lastPosition.y - this.initialPosition.y,
    }

    if (!this.helper) {
      this.createHelper()

      if (!this.helper) {
        return
      }
    }

    this.helper.style[`${vendorPrefix}Transform`] = `translate3d(${translate.x}px, ${translate.y}px, 0)`

    this.helperRect = this.helper.getBoundingClientRect()
  }

  checkNodesIntersection() {
    if (this.mode === 'idle') {
      return
    }

    const { axis } = this.props

    const nodes = this.manager.getActiveNodes()

    const helperCenterX = this.helperRect.left + this.helperRect.width / 2
    const helperCenterY = this.helperRect.top + this.helperRect.height / 2

    const scrollDelta = this.getScrollDelta()

    let hoveredNode: ReactDnD.ManagerNode

    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index]

      if (!node.dndManagerData) {
        // strange issue
        continue
      }

      const { left, top, width, height } = node.dndManagerData.initialRect

      // revert scroll delta, because we got initialRect before the scroll
      const nodeX = left - scrollDelta.left
      const nodeY = top - scrollDelta.top

      const isIntersectX = axis === 'y' || (
        helperCenterX <= (nodeX + width)
        && helperCenterX >= nodeX
      )

      const isIntersectY = axis === 'x' || (
        helperCenterY <= (nodeY + height)
        && helperCenterY >= nodeY
      )

      if (isIntersectX && isIntersectY) {
        hoveredNode = node
      }

      // TODO add some optimization if needed: when axis is Y, we can skip all bellow the helper - added on 2020-08-01 by maddoger
    }

    if (hoveredNode && hoveredNode !== this.lastHoveredNode) {
      this.lastHoveredNode = hoveredNode
      this.animateNodes()
    }
  }

  animateNodes() {
    if (this.mode === 'idle') {
      return
    }

    const nodes = this.manager.getActiveNodes()

    const initialIndex = nodes.indexOf(this.activeNode)
    const hoveredIndex = nodes.indexOf(this.lastHoveredNode)

    const delta = hoveredIndex - initialIndex > 0 ? 1 : -1
    const lowIndex = delta > 0 ? initialIndex : hoveredIndex
    const highIndex = delta > 0 ? hoveredIndex : initialIndex

    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index]

      if (!node.dndManagerData) {
        continue
      }

      const offset = node.dndManagerData.offset

      if (
        delta > 0 && index > lowIndex && index <= highIndex
        || delta < 0 && index >= lowIndex && index < highIndex
      ) {
        const nodeBounds = node.dndManagerData.initialRect
        const closestNodeBounds = nodes[index - delta].dndManagerData.initialRect

        offset.x = closestNodeBounds.left - nodeBounds.left
        offset.y = closestNodeBounds.top - nodeBounds.top
      }
      else {
        offset.x = 0
        offset.y = 0
      }

      node.style[`${vendorPrefix}TransitionDuration`] = `${300}ms`
      node.style[`${vendorPrefix}Transform`] = `translate3d(${offset.x}px, ${offset.y}px, 0)`
    }
  }

  handleContainerMove = (event: MouseEvent | TouchEvent) => {
    if (this.mode === 'idle') {
      return
    }

    if (!this.isDelayed) {
      // prevent scroll
      event.preventDefault()
    }
  }

  handleStart = (event: MouseEvent | TouchEvent) => {
    const { touchDelay, mouseDelay } = this.props

    const isMouseRightButtonClick = 'button' in event && event.button === 2

    if (isMouseRightButtonClick) {
      return
    }

    // find node to move
    const activeNode = findClosest(event.target as Node, (node) => Boolean(node.dndData)) as ReactDnD.ManagerNode
    const isInContainerNode = this.containerRef.current.contains(activeNode)

    if (!activeNode || activeNode.dndData.disabled || !isInContainerNode) {
      return
    }

    if (this.mode !== 'idle') {
      this.cleanup()
    }

    // check handle, if target in the handle we good to continue
    const byHandle = (activeNode.dndData.handle?.contains(event.target as Node))

    const mode = isTouchEvent(event) ? 'touch' : 'mouse'

    this.initialEvent = event
    this.initialPosition = getCursorPosition(event)
    this.activeNode = activeNode

    // dragging by handle shouldn't have a delay
    this.isDelayed = !byHandle

    if (!this.isDelayed) {
      event.preventDefault()
    }

    this.initDrag(mode)

    clearTimeout(this.delayTimeout)

    this.delayTimeout = setTimeout(() => {
      this.isDelayed = false
    }, mode === 'touch' ? touchDelay : mouseDelay)
  }

  handleMove = (event: MouseEvent | TouchEvent) => {
    if (this.mode === 'idle') {
      return
    }

    if (this.isDelayed) {
      // when user moved the cursor we cancel delayed start
      const position = getCursorPosition(event)

      const distance = (position.x - this.initialPosition.x) ** 2 + (position.y - this.initialPosition.y) ** 2

      if (distance > 25) {
        this.cleanup()
      }

      return
    }

    event.preventDefault()

    this.lastPosition = getCursorPosition(event)

    if (window.getSelection) {
      // disable text selection
      const selection = window.getSelection()

      if (selection.rangeCount) {
        selection.removeAllRanges()
      }
    }

    this.updateHelper()
    this.checkNodesIntersection()

    this.autoScroller.update(this.helperRect)
  }

  handleEnd = () => {
    if (!this.isDelayed) {
      const { onDragEnd } = this.props

      if (typeof onDragEnd === 'function' && this.activeNode && this.lastHoveredNode) {
        const oldIndex = this.activeNode.dndData.index
        const newIndex = this.lastHoveredNode.dndData.index

        if (
          Number.isFinite(oldIndex)
          && Number.isFinite(newIndex)
          && (oldIndex > -1)
          && (newIndex > -1)
          && (oldIndex !== newIndex)
        ) {
          onDragEnd(oldIndex, newIndex)
        }
      }
    }

    this.cleanup()
  }

  handleCancel = () => {
    this.cleanup()
  }

  handleKeyStart = (event: KeyboardEvent) => {
    const { keyCode, target } = event

    if (this.mode !== 'idle' || keyCode !== 32) {
      return
    }

    const activeNode = target as ReactDnD.ManagerNode

    if (!activeNode.dndData || activeNode.dndData.disabled) {
      return
    }

    event.preventDefault()

    const rect = activeNode.getBoundingClientRect()
    const x = rect.left + rect.width / 2
    const y = rect.top + rect.height / 2

    this.activeNode = activeNode
    this.initialPosition = { x, y }
    this.lastPosition = { x, y }
    this.isDelayed = false

    this.createHelper()
    this.helper.focus()
    this.initDrag('keyboard')
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (this.mode !== 'keyboard') {
      return
    }

    const { keyCode } = event

    event.preventDefault()

    if (keyCode === 27) {
      // esc
      this.handleCancel()
    }
    else if (keyCode === 32) {
      // space
      this.handleEnd()
    }
    else if (keyCode === 38) {
      // up
      this.handleKeyMove(-1)
    }
    else if (keyCode === 40) {
      // down
      this.handleKeyMove(1)
    }
  }

  handleKeyMove = (delta: number) => {
    const nodes = this.manager.getActiveNodes()

    let hoveredIndex = nodes.indexOf(this.lastHoveredNode)

    hoveredIndex = Math.max(0, Math.min(nodes.length - 1, hoveredIndex + delta))

    const lastHoveredNode = nodes[hoveredIndex]
    lastHoveredNode.scrollIntoView({
      block: 'nearest',
      behavior: 'auto',
    })

    const scrollDelta = this.getScrollDelta()

    const rect = lastHoveredNode.dndManagerData.initialRect
    const x = rect.left + rect.width / 2 - scrollDelta.left
    const y = rect.top + rect.height / 2 - scrollDelta.top

    this.lastPosition = { x, y }

    this.updateHelper()
    this.checkNodesIntersection()
  }

  handleScroll = (offset) => {
    this.lastPosition.x += offset.left
    this.lastPosition.y += offset.top
  }

  getScrollDelta() {
    return {
      left: this.scrollingContainer.scrollLeft - this.initialScroll.left,
      top: this.scrollingContainer.scrollTop - this.initialScroll.top,
    }
  }

  initDrag(mode: Mode) {
    this.lastHoveredNode = this.activeNode

    this.initialScroll = {
      left: this.scrollingContainer.scrollLeft,
      top: this.scrollingContainer.scrollTop,
    }

    // save elements initial rects
    this.manager.getNodes().forEach((node) => {
      node.dndManagerData = {
        initialRect: node.getBoundingClientRect(),
        offset: { x: 0, y: 0 },
      }
    })

    if (mode === 'touch') {
      document.addEventListener('touchmove', this.handleMove, { passive: false })
      document.addEventListener('touchend', this.handleEnd, { passive: true })
      document.addEventListener('touchcancel', this.handleCancel, { passive: true })
    }
    else if (mode === 'mouse') {
      document.addEventListener('mousemove', this.handleMove, { passive: false })
      document.addEventListener('mouseup', this.handleEnd, { passive: true })
    }
    else if (mode === 'keyboard') {
      this.helper.addEventListener('keydown', this.handleKeyDown)
      this.helper.addEventListener('blur', this.handleCancel)
    }

    this.mode = mode
  }

  cleanup() {
    if (this.mode === 'idle') {
      return
    }

    if (this.mode === 'touch') {
      document.removeEventListener('touchmove', this.handleMove)
      document.removeEventListener('touchend', this.handleEnd)
      document.removeEventListener('touchcancel', this.handleCancel)
    }
    else if (this.mode === 'mouse') {
      document.removeEventListener('mousemove', this.handleMove)
      document.removeEventListener('mouseup', this.handleEnd)
    }
    else if (this.mode === 'keyboard') {
      this.helper.removeEventListener('keydown', this.handleKeyDown)
      this.helper.removeEventListener('blur', this.handleCancel)
    }

    // delay
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout)
      this.delayTimeout = null
    }
    this.isDelayed = false

    this.autoScroller.clear()

    if (this.helper) {
      this.helper.remove()
      this.helper = null
    }

    if (this.activeNode) {
      this.activeNode.style.visibility = ''
      this.activeNode = null
    }

    this.lastHoveredNode = null
    this.initialEvent = null

    this.manager.getNodes().forEach((node) => {
      delete node.dndManagerData
      node.style[`${vendorPrefix}TransitionDuration`] = ''
      node.style[`${vendorPrefix}Transform`] = ''
    })

    this.mode = 'idle'
  }


  render() {
    const { children } = this.props

    return (
      <ManagerProvider value={this.manager}>
        {
          React.cloneElement(React.Children.only(children), {
            ref: this.containerRef,
          })
        }
      </ManagerProvider>
    )
  }
}


export default DragLayer
