import logger from 'logger'
import analyticsWrapper from '../wrapper'
import { getAnalyticsContext } from '../context'


type Events = Analytics.Events

type Methods = {
  track: Analytics.TrackFunction<Events>
  onInit: <Result = void>(cb: () => Result) => Result
  getDistinctId: () => string
  identify: (uniqueId?: string) => void
  unregister: (key: string) => void
  register: (properties: Record<string, any>, days?: number) => void
  reset: () => void
  peopleSet: (keys: { [index: string]: any }, callback?: () => void) => void
  peopleSetOnce: (keys: { [index: string]: any }, callback?: () => void) => void
}

const mixpanel = analyticsWrapper<Methods>({
  entityKey: 'mixpanel',
  // wait for mixpanel initialization. get_distinct_id is available only when mixpanel is initialized
  entityResolver: () => typeof window.mixpanel?.get_distinct_id === 'function',
  resolveMethods: {
    onInit: (cb) => cb(),
    track: (eventName: string, eventProperties = undefined) => {
      const { page, pageType } = getAnalyticsContext()

      const properties = {
        page,
        pageType,
        ...eventProperties,
      }

      logger.info({ properties }, `[Mixpanel] track %s`, eventName)

      // ATTN if you need callback argument, create a new type for tracking function
      window.mixpanel.track(eventName, properties)
    },
    getDistinctId: () => window.mixpanel.get_distinct_id(),
    identify: true,
    unregister: true,
    register: true,
    reset: true,
    peopleSet: (...args) => {
      window.mixpanel.people.set(...args)
    },
    peopleSetOnce: (...args) => {
      window.mixpanel.people.set_once(...args)
    },
  },
})


export default mixpanel
