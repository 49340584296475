import { getSvgData, request } from './util'


const SVG_NAMESPACE = 'http://www.w3.org/2000/svg'

class BrowserRuntime implements SvgProvider.Runtime {
  private cache: Map<string, SvgProvider.SvgData>
  private spriteElement: SVGSVGElement

  constructor() {
    this.cache = new Map()
    this.fillCacheFromExistingSprite()
  }

  fillCacheFromExistingSprite() {
    const existingSpriteElement = document.getElementById('svg-sprite') as any as SVGSVGElement

    if (existingSpriteElement) {
      this.spriteElement = existingSpriteElement
      // fill cache with symbols we have in sprite
      document.querySelectorAll<SVGSymbolElement>('symbol[data-filename], svg[data-filename]').forEach((symbolElement) => {
        const symbolId = symbolElement.id
        const fileName = symbolElement.dataset.filename
        const raw = symbolElement.outerHTML

        this.loadSvgDataFromSource(fileName, raw, symbolId)
      })
    }
    else {
      this.spriteElement = document.createElementNS(SVG_NAMESPACE, 'svg')
      this.spriteElement.setAttribute('id', 'svg-sprite')
      this.spriteElement.setAttribute('class', 'visually-hidden')
      document.body.prepend(this.spriteElement)
    }
  }

  loadSvgDataFromSource(filename: string, source: string, symbolId?: string): SvgProvider.SvgData {
    // because this function can be used only from existing sprite, we don't add the file to sprite
    const svgData = getSvgData(source)
    svgData.symbolId = symbolId

    this.cache.set(filename, svgData)

    return svgData
  }

  getSvgData(filename: string): SvgProvider.SvgData {
    return this.cache.get(filename)
  }

  isLoaded(filename: string, symbolId?: string): boolean {
    if (!this.cache.has(filename)) {
      return false
    }

    if (symbolId) {
      return Boolean(document.querySelector<SVGSymbolElement>(`symbol[data-filename="${filename}"]`))
    }

    return true
  }

  async loadSvgData(filename: string, symbolId?: string): Promise<SvgProvider.SvgData> {
    if (this.isLoaded(filename, symbolId)) {
      return this.cache.get(filename)
    }

    const svgData = await request(filename)
    svgData.symbolId = symbolId

    // if it was added in another promise, we shouldn't add it again
    if (!this.isLoaded(filename, symbolId)) {
      this.cache.set(filename, svgData)

      if (symbolId) {
        const bodyWrapper = document.createElementNS(SVG_NAMESPACE, 'symbol')
        bodyWrapper.innerHTML = svgData.body.trim()

        Object.keys(svgData.attributes).forEach((key) => {
          bodyWrapper.setAttribute(key, svgData.attributes[key])
        })

        bodyWrapper.setAttribute('id', symbolId)
        bodyWrapper.dataset['filename'] = filename

        this.spriteElement.appendChild(bodyWrapper)
      }
    }

    return svgData
  }
}


export default BrowserRuntime
