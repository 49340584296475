import React, { forwardRef } from 'react'
import cx from 'classnames'
import { getGlobalHtmlAttrs, type GlobalHTMLAttrs } from 'helpers/getters'

import useSvgData from './useSvgData'


type SvgProps = GlobalHTMLAttrs & {
  className?: string
  src: string
  source?: string
  width?: number
  height?: number
  aspect?: number
  symbolId?: string
  isInline?: boolean
}

const Svg = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { className, src, source, aspect = 1, width, height, symbolId, isInline = false, ...rest } = props
  const htmlAttrs = getGlobalHtmlAttrs(rest)
  const svgData = useSvgData(src, source, isInline ? undefined : symbolId)

  const { body, attributes } = svgData || {}
  const defaultAttributes = {
    ref,
    role: 'img',
    className: cx('inline-block', className),
    width: width || aspect,
    height: height || 1,
    viewBox: `0 0 ${aspect} 1`,
  }

  if (!isInline) {
    return (
      <svg
        {...defaultAttributes}
        {...attributes}
        {...htmlAttrs}
      >
        <use href={`#${symbolId}`} xlinkHref={`#${symbolId}`} />
      </svg>
    )
  }

  if (body && attributes) {
    return (
      <svg
        {...defaultAttributes}
        {...attributes}
        {...htmlAttrs}
        data-filename={src}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    )
  }

  return (
    <svg {...defaultAttributes} />
  )
})


export default React.memo(Svg)
