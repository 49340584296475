import cookieStorage from 'cookie-storage'
import logger from 'logger'
import createEventsSaver from './eventsSaver'


type Options<Methods> = {
  entityKey: string
  entityResolver?: () => boolean
  resolveMethods: {
    [K in keyof Methods]: boolean | Methods[K]
  }
}

const areEventsDisabled = __SERVER__ || cookieStorage.getItem('noAnalytics')

const analyticsWrapper = <Methods extends object>(options: Options<Methods>): Methods => {
  if (__SERVER__) {
    return
  }

  const { entityKey, entityResolver, resolveMethods } = options

  const eventsSaver = createEventsSaver(entityKey)
  const resolver = entityResolver || (() => Boolean(window[entityKey]))

  let isEntityResolved = resolver()
  let entityResolveTimer = null
  const waitList = []

  if (!isEntityResolved) {
    entityResolveTimer = setInterval(() => {
      const isEntityResolvedNow = resolver()

      if (isEntityResolvedNow) {
        clearInterval(entityResolveTimer)
        entityResolveTimer = null
        isEntityResolved = true

        while (waitList.length > 0) {
          const resolveMethod = waitList.shift()
          resolveMethod()
        }
      }
    }, 250)

    setTimeout(() => {
      if (entityResolveTimer) {
        clearInterval(entityResolveTimer)
        entityResolveTimer = null
        // empty the waitlist to prevent memory leaks
        waitList.length = 0
        logger.warn(`%s initialization failed`, entityKey)
      }
    }, 20000)
  }

  return Object.keys(resolveMethods).reduce((acc, methodName) => {
    const resolveMethod = (...args) => {
      eventsSaver({
        methodName,
        data: args,
      })

      if (areEventsDisabled) {
        return
      }

      try {
        if (resolveMethods[methodName] === true) {
          return window[entityKey][methodName](...args)
        }

        return resolveMethods[methodName](...args)
      }
      catch (error) {
        logger.warn(error)
      }
    }

    acc[methodName] = (...args) => {
      if (isEntityResolved) {
        return resolveMethod(...args)
      }
      else {
        // Do not push to a waitlist if entity is failed to resolve
        if (!entityResolveTimer) {
          return
        }
        waitList.push(() => resolveMethod(...args))
      }
    }

    return acc
  }, {}) as Methods
}


export default analyticsWrapper
