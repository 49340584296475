import { useMemo, useEffect } from 'react'

import Form, { type FormOpts } from './Form'
import useForceUpdate from './useForceUpdate'


const useForm = <T extends {}>(opts: FormOpts<T>, deps?: any[]) => {
  const forceUpdate = useForceUpdate()
  const form = useMemo(() => new Form(opts), deps || [])

  useEffect(() => {
    form.on('force update', forceUpdate)

    return () => {
      form.off('force update', forceUpdate)
    }
  }, [ forceUpdate, form ])

  return form
}


export default useForm
