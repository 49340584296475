type Listener = (entry: IntersectionObserverEntry) => void

const createIndividualObserver = (observerProps: IntersectionObserverInit) => {
  let observer

  return {
    observe: (node: HTMLElement, listener: Listener) => {
      observer = new IntersectionObserver(([ entry ]) => {
        listener(entry)
      }, observerProps)

      observer.observe(node)
    },
    unobserve: (node: HTMLElement) => {
      observer.unobserve(node)
      observer = undefined
    },
  }
}


export default createIndividualObserver
