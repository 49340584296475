import type { LoggerInterface } from '@scentbird/logger'


let logger: LoggerInterface

if (__TEST__) {
  logger = require('./test').default
}
else if (__SERVER__) {
  logger = require('./server').default
}
else {
  logger = require('./client').default
}


export default logger
