export type FormatPriceOptions = {
  currency?: string
  isCents?: boolean
  toFixed?: number
  removeZeroCents?: boolean
  withCurrency?: boolean
}

const formatPrice = (locale: string, value: number, options: FormatPriceOptions = {}) => {
  const {
    currency = 'USD',
    isCents = true,
    toFixed = 2,
    removeZeroCents = false,
    withCurrency = true, // change mode from currency to a number formatting
  } = options

  const amountValue = Number(isCents ? value / 100 : value)

  if (!withCurrency) {
    // simple number for data attributes and schemas
    return amountValue.toFixed(toFixed)
  }

  const result = Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: toFixed,
    minimumFractionDigits: removeZeroCents && (amountValue % 1 === 0) ? 0 : toFixed,
    useGrouping: false,
  }).format(Math.abs(amountValue))

  // we modify sign display
  if (amountValue < 0) {
    return `-\u00A0${result}`
  }

  return result
}

export default formatPrice
