import React from 'react'
import { type Match } from './types'
import { RouteParamsContext } from './contexts'


export type RouteProps = {
  path?: string
  match?: Match // controlled by Switch
  component?: React.ElementType
  children?: React.ReactNode
} & {
  // Props passed to component
  [key: string]: any
}

export const Route: React.FunctionComponent<RouteProps> = ({ children, path, match, component: Component, ...props }) => {
  if (!match) {
    return null
  }

  // `props.match` is present - Route is controlled by the Switch
  const { params } = match

  // React-Router style `component` prop
  const routeChildren = Component ? (
    <Component {...props} />
  ) : children

  return (
    <RouteParamsContext.Provider value={params}>
      {routeChildren}
    </RouteParamsContext.Provider>
  )
}
