export type Procedure = (...args: any[]) => void

export interface DebouncedFunction<F extends Procedure> {
  (this: ThisParameterType<F>, ...args: Parameters<F>): void
  cancel: () => void
}

function debounce<F extends Procedure>(func: F, wait: number, immediate?: boolean): DebouncedFunction<F> {
  let timeout: ReturnType<typeof setTimeout>

  const result = function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    const context = this

    const later = function () {
      timeout = null

      if (!immediate) {
        func.apply(context, args)
      }
    }

    const callNow = immediate && timeout === null

    if (timeout !== null) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(context, args)
    }
  }

  result.cancel = () => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
  }

  return result
}


export default debounce
