import React, { useRef, useEffect } from 'react'
import { useDeepMemo } from 'hooks'

import { createMasterObserver, createIndividualObserver } from './util'


type Listener = (entry: IntersectionObserverEntry) => void

type Opts = {
  observerProps?: IntersectionObserverInit
  once?: boolean
  delay?: number
}

type Result = [
  React.RefObject<any>,
  () => void,
  () => void
]

const useEntryListener = (listener: Listener, opts: Opts = {}): Result => {
  const ref = useRef()

  const listenerRef = useRef(listener)
  const unobserveRef = useRef(() => {})
  const refreshRef = useRef(() => {})

  // to fix reinitialization on listener change
  listenerRef.current = listener

  // ATTN this is important to prevent infinity updates
  const { once, observerProps, delay = 0 } = opts
  const observerOptions = useDeepMemo(() => observerProps, [ observerProps ])

  useEffect(() => {
    const node = ref.current

    if (!node) {
      return
    }

    const observer = observerOptions ? createIndividualObserver(observerOptions) : createMasterObserver()

    const observeWithDelay = () => {
      // we can't call it twice
      unobserveRef.current = () => {
        observer.unobserve(node)
        unobserveRef.current = () => {
        }
      }

      const listener = (entry) => {
        if (once) {
          // setEntry called once when target become visible in viewport
          if (entry.isIntersecting) {
            listenerRef.current(entry)
            unobserveRef.current()
          }
        }
        else {
          listenerRef.current(entry)
        }
      }

      observer.observe(node, listener)

      refreshRef.current = () => {
        observer.unobserve(node)
        observer.observe(node, listener)
      }
    }

    let timeoutId: string | number | NodeJS.Timeout

    if (delay > 0) {
      timeoutId = setTimeout(observeWithDelay, delay)
    }
    else {
      observeWithDelay()
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      unobserveRef.current()
    }
  }, [ once, observerOptions, delay ])

  return [ ref, unobserveRef.current, refreshRef.current ]
}


export default useEntryListener
