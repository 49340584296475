type Result = { [key: string]: string }

/**
 * variable: "{sex, select, =0 {nobody} female {perfume} male {cologne} unisex {fragrance}}"
 *
 * result: { female: 'perfume', male: 'cologne', unisex: 'fragrance' }
 */
const getVariableValues = (variable: string): Result => {
  const result: Result = {}
  const reg = /(\S+)\s+{([^}]*)}/g
  let match: any
  while ((match = reg.exec(variable)) !== null) {
    let key = match[1]
    const value = match[2]
    if (key.startsWith('=')) {
      key = key.slice(1)
    }
    result[key] = value
  }

  return result
}

export default getVariableValues
