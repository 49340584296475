import React, { Fragment } from 'react'

import { useIntl } from './IntlContext'
import replaceReactComponents from './util/replaceReactComponents'


type MessageProps = {
  value: string | Intl.Message
  tag?: string
  html?: boolean
}

const Message: React.FunctionComponent<MessageProps> = (props) => {
  const { value, tag = 'span', html } = props

  const intl = useIntl()

  if (!value) {
    // TODO handle error - added on 10/13/20 by pavelivanov
    return null
  }

  if (typeof value === 'string') {
    if (html) {
      return React.createElement(tag, {
        dangerouslySetInnerHTML: { __html: value },
      })
    }

    // TODO handle error - added on 10/13/20 by pavelivanov
    return <Fragment>{value}</Fragment>
  }

  const { values, components, ...message } = value

  const formattedMessage: string = intl.formatMessage(message, values)

  if (components) {
    const formattedMessageArr: any[] = replaceReactComponents(formattedMessage, components)

    const children = formattedMessageArr.filter((messageItem) => messageItem).map((messageItem, index) => {
      if (typeof messageItem === 'object') {
        return <Fragment key={index}>{messageItem}</Fragment>
      }

      return <span key={index} dangerouslySetInnerHTML={{ __html: messageItem }} />
    })

    return <Fragment>{children}</Fragment>
  }

  if (html) {
    return React.createElement(tag, {
      dangerouslySetInnerHTML: { __html: formattedMessage },
    })
  }

  return <Fragment>{formattedMessage}</Fragment>
}


export default Message
