import React, { forwardRef, useCallback, type MouseEventHandler } from 'react'
import { useNavigate } from './hooks'


export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  to?: string
  scroll?: boolean
  replace?: boolean
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { to, href = to, children, onClick, scroll = true, replace, ...rest } = props

  const navigate = useNavigate()

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    // ignores the navigation when clicked using right mouse button or
    // by holding a special modifier key: ctrl, command, win, alt, shift
    if (
      event.ctrlKey
      || event.metaKey
      || event.altKey
      || event.shiftKey
      || event.button !== 0
    ) {
      return
    }

    if (typeof onClick === 'function') {
      onClick(event)
    }

    if (!event.defaultPrevented) {
      event.preventDefault()
      navigate(href, {
        scroll,
        replace,
      })
    }
  }, [ navigate, href, onClick, scroll, replace ])

  // wraps children in `a` if needed
  const extraProps = {
    ...rest,
    href,
    onClick: handleClick,
  }

  return (
    <a {...extraProps} ref={ref}>{children}</a>
  )
})

Link.displayName = 'Link'
