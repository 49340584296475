import React, { useEffect, useLayoutEffect, useState } from 'react'
import logger from 'logger'

import { manager } from './manager'


const useIsomorphicLayoutEffect = __CLIENT__ ? useLayoutEffect : useEffect

const ModalsRenderer: React.FC = () => {
  const [ _, update ] = useState(0)

  useIsomorphicLayoutEffect(() => {
    const listener = () => {
      update((value) => value + 1)
    }

    manager.subscribe(listener)
    // force update to rerender
    listener()

    return () => {
      manager.unsubscribe(listener)
    }
  }, [])

  const nodes = []
  let index = -1

  for (const [ id, { name, props, closeModal, isStandalone } ] of manager.getState().entries()) {
    // we use global index for every modal
    index++

    // skip standalone modals, they render themselves
    if (isStandalone) {
      continue
    }

    if (!manager.registry.has(name)) {
      logger.error(`Modal "${name}" not found`)
      continue
    }

    const Component = manager.registry.get(name)

    nodes.push(
      <Component
        key={id}
        name={name}
        closeModal={closeModal}
        index={index}
        {...props}
      />
    )
  }

  return (
    <>
      {nodes}
    </>
  )
}


export default ModalsRenderer
