type EventMap = Record<string, (...args) => void>
type EventListeners<T> = {
  [K in keyof T]: T[K][]
}

class EventEmitter<T extends EventMap, K extends keyof T = keyof T> {
  private _listeners: EventListeners<T> = {} as EventListeners<T>

  public addListener(eventName: K, listener: T[K]) {
    if (!this._listeners[eventName]) {
      this._listeners[eventName] = []
    }
    this._listeners[eventName].push(listener)
  }

  public once(eventName: K, listener: T[K]) {
    const wrapper = ((...args) => {
      listener(...args)
      this.removeListener(eventName, wrapper)
    }) as T[K]

    this.addListener(eventName, wrapper)
  }

  public removeListener(eventName: K, listener: T[K]) {
    if (!this._listeners[eventName]?.length) {
      return
    }

    const listenerIndex = this._listeners[eventName].indexOf(listener)
    if (listenerIndex !== -1) {
      this._listeners[eventName].splice(listenerIndex, 1)
    }
  }

  public removeAllListeners(eventName?: K) {
    if (eventName) {
      if (this._listeners[eventName]) {
        this._listeners[eventName] = []
      }
    }
    else {
      this._listeners = {} as EventListeners<T>
    }
  }

  public emit(eventName: K, ...args: Parameters<T[K]>): boolean {
    if (!this._listeners[eventName]?.length) {
      return false
    }

    this._listeners[eventName].forEach((listener) => {
      listener(...args)
    })
  }

  public hasListeners(eventName: K): boolean {
    return this._listeners[eventName]?.length > 0
  }

  public listeners(eventName: K): T[K][] {
    return this._listeners[eventName] || []
  }
}


export default EventEmitter
