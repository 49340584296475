let provider: DeviceProvider

if (__DEV__ && (typeof __STORYBOOK__ === 'undefined' || !__STORYBOOK__)) {
  provider = require('./DevProvider').default
}
else {
  provider = require('./Provider').default
}


export { default as Context } from './Context'
export default provider
