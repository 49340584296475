import { compile, pathToRegexp } from 'path-to-regexp'
import type { Path, To, SearchParams } from './types'


export const getHref = (to: To): string => {
  return typeof to === 'string' ? to : createPath(to)
}

// creates string path from object path
export const createPath = ({
  pathname = '/',
  search = '',
  hash = '',
}: Partial<Path>): string => {
  let result = pathname

  if (search && search !== '?') {
    result += search.charAt(0) === '?' ? search : `?${search}`
  }

  if (hash && hash !== '#') {
    result += hash.charAt(0) === '#' ? hash : `#${search}`
  }

  return result
}

// create Path from string
export const parsePath = (path: string): Partial<Path> => {
  const result: Path = {
    pathname: undefined,
    search: undefined,
    hash: undefined,
  }

  if (path) {
    const hashIndex = path.indexOf('#')
    if (hashIndex >= 0) {
      result.hash = path.substring(hashIndex)
      path = path.substring(0, hashIndex)
    }

    const searchIndex = path.indexOf('?')
    if (searchIndex >= 0) {
      result.search = path.substring(searchIndex)
      path = path.substring(0, searchIndex)
    }

    if (path) {
      result.pathname = path
    }
  }

  return result
}


// TODO it doesn't work with arrays - added on 2021-12-22 by maddoger
export const getSearchParams = (search) => {
  const params = {}

  new URLSearchParams(search).forEach((value, key) => {
    params[key] = value
  })

  return params
}

export const mergeSearchParams = (url: string, searchParams: SearchParams | string) => {// me
  const match = url.match(/^(.*?)(|\?.*?)$/)
  const pathname = match[1]
  const params = new URLSearchParams(match[2])
  // TODO check if it works with arrays - added on 2022-02-22 by maddoger
  // @ts-ignore
  const newParams = new URLSearchParams(searchParams)

  newParams.forEach((value, name) => {
    if (value !== undefined && value !== null && typeof value !== 'undefined' && value !== 'undefined' && value !== 'null') {
      params.set(name, value)
    }
    else {
      params.delete(name)
    }
  })

  // delete redirect param if we're already on it
  if (params.has('redirect') && params.get('redirect') === pathname) {
    params.delete('redirect')
  }

  // delete tokens from redirect url for security reasons
  // params.delete('ltn') // keep for sentry
  // params.delete('magicToken')

  // replace `?` without params
  return `${pathname}?${params.toString()}`.replace(/\?$/, '')
}

type GetLinkParams = {
  [key: string]: string | number | boolean
}


const linkCache = new Map<string, any>()

export const getLinkWithParams = (link: string, params?: GetLinkParams, encode = false) => {
  let compiledLink = linkCache.get(link)

  if (!compiledLink) {
    compiledLink = compile(link, {
      encode: encode ? encodeURIComponent : undefined,
    })
    linkCache.set(link, compiledLink)
  }

  return compiledLink(params)
}

type MakeRegexp = {
  keys: { name: string }[]
  regexp: RegExp
}

export const createMatcher = () => {
  const cache = new Map<string, MakeRegexp>()

  // obtains a cached regexp version of the pattern
  const getRegexp = (pattern: string) => {
    let result = cache.get(pattern)

    if (!result) {
      const keys = []
      const regexp = pathToRegexp(pattern, keys)
      result = {
        regexp,
        keys,
      }

      cache.set(pattern, result)
    }

    return result
  }

  return (pattern: string, path: string) => {
    const { regexp, keys } = getRegexp(pattern || '')
    const out = regexp.exec(path)

    if (!out) {
      return null
    }

    // formats an object with matched params
    const params = keys.reduce((params, key, i) => {
      params[key.name] = out[i + 1]
      return params
    }, {})

    return {
      path: pattern,
      params,
    }
  }
}
